.App {
  margin: 20px 20px 0 20px;
}
.Title {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}
.status-paid {
  color: green;
  font-weight: bold;
}
.status-unpaid {
  color: red;
  font-weight: bold;
}
.status-draft {
  color: rgb(248, 224, 6);
  font-weight: bold;
}
.Data {
  text-align: center;
}
.downloadbutton {
  border: none;
  background-color: white;
  margin-left: 55px;
}
.filter-month-year {
  width: 17%;
  height: 35px;
  display: flex;
  flex-direction: row;
}
.filter-project-name {
  height: 35px;
}
.monthlabel {
  margin-right: 50px;
}
.inputprojectname {
  width: 30%;
}
.inputyear {
  width: 120%;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.filter-label {
  font-weight: bold;
  margin-right: 5px;
  color: #333;
}

.filter-input,
.filter-select,
.filter-year {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
  width: 100px;
  margin-right: 50px;
}

.filter-input:focus,
.filter-select:focus,
.filter-year:focus {
  border-color: #007bff;
}

.filter-select + .filter-select,
.filter-year {
  margin-left: 5px;
}

.downloadbtn {
  height: 50px;
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.Heading {
  margin-bottom: -40px;
}
