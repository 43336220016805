.dashboard-emptystate {
  font-size: 18px;
  text-align: center;
  margin: 100px 0;
}
.dashboardtitle {
  border-bottom: 2px solid #c10e21;
  padding: 20px 0px;
}
.totalproject {
  border: 2px solid #6895e6;
}
.activeproject {
  border: 2px solid #f291a5;
}
.upcomingproject {
  border: 2px solid #e7b924;
}
.completedprojects {
  border: 2px solid #35d6a6;
}
body {
  background-color: #f9f9fa;
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .padding {
    padding: 1rem;
  }
}

.padding {
  padding: 5rem;
}

.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}
.card-d {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.card-container {
  display: flex;
}
.card-d-container {
  display: flex;
}
.card {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem;
}
.card-d {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: large;
  background-color: rgba(19, 24, 44, 0.03);
  border-bottom: 1px solid rgba(19, 24, 44, 0.125);
}
.card-d-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: large;
  background-color: rgba(19, 24, 44, 0.03);
  border-bottom: 1px solid rgba(19, 24, 44, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-d-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer,
.card-header {
  background-color: transparent;
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box;
}
.card-d-footer,
.card-d-header {
  background-color: transparent;
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box;
}
.card-body {
  min-height: 470px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.card-d-body {
  min-height: 470px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.r-card-body {
  min-height: 519px;
  padding: 21px 30px 20px 31px;
}
