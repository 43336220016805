.card-body {
  max-height: 519px;
  padding: 21px 30px 20px 31px;
}
.green {
  background-color: #1fd286;
}
.blue {
  background-color: #57b8ff;
}
.red {
  background-color: #c10e21;
}
.peach {
  background-color: #ff5252;
}
.purple {
  background-color: #6a00b0;
  border-bottom: 1px solid #c1cfdc;
}
.proj {
  padding-left: 16px;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #131523;
  }
  p {
    font-size: 14px;
    color: #7e84a3;
    margin-top: -5px;
  }
}
.moreinsights {
  font: normal normal medium 16px/20px Poppins;
  border: none;
  color: #c10e21;
  background: none;
}
.icon {
  color: #c10e21;
}
.button {
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-top: 15px;
}
.title {
  padding-top: 10px;
  padding-bottom: 10px;
}
.fileicon {
  margin-top: 16px;
}
.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
}
