.circle {
  font-size: 16px;
  width: 40px;
  height: 42px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}
