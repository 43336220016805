.box {
  max-height: 115px;
  border-radius: 8px;
  padding: 24px;
}
.logo {
  width: 50px;
  height: 50px;
}
.data {
  p:nth-of-type(1) {
    font: normal normal 600 14px/23px Montserrat;
    color: #343a40;
  }
  p:nth-of-type(2) {
    font: normal normal bold 26px/23px Montserrat;
    color: #343a40;
  }
}
